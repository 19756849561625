import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0048\u0065\u00e7\u0020\u0062\u0069\u0072\u0020\u006e\u0259\u0074\u0069\u0063\u0259\u0020\u0074\u0061\u0070\u0131\u006c\u006d\u0061\u0064\u0131",
        messages: {
            required: '\u062e\u0627\u0646\u06d2\u0020\u06a9\u0648\u0020\u0628\u06be\u0631\u06cc\u06ba',
            phone: {
                incorrect: '\u063a\u0644\u0637\u0020\u0641\u0648\u0646\u0020\u0646\u0645\u0628\u0631'
            },
            email: {
                incorrect: '\u0059\u0061\u006e\u006c\u0131\u015f\u0020\u00fc\u006e\u0076\u0061\u006e\u0020\u0066\u006f\u0072\u006d\u0061\u0074\u0131',
                symbolNotFound: '\u067e\u062a\u06d2\u0020\u06a9\u06d2\u0020\u0627\u0646\u062f\u0631\u0020\u0022\u0040\u0022\u0020\u06a9\u06d2\u0020\u06c1\u0646\u062f\u0633\u06d2\u0020\u06a9\u0627\u0020\u06c1\u0648\u0646\u0627\u0020\u0636\u0631\u0648\u0631\u06cc\u0020\u06c1\u06d2\u06d4\u0020\u0022\u0040\u0022\u0020\u06a9\u0627\u0020\u06c1\u0646\u062f\u0633\u06c1\u0020\u067e\u062a\u06d2\u0020\u06a9\u06d2\u0020\u0627\u0646\u062f\u0631\u0020\u0633\u06d2\u0020\u063a\u0627\u0626\u0628\u0020\u06c1\u06d2',
                addressIsNotFull: '\u0022\u0040\u0022\u0020\u06a9\u06d2\u0020\u06c1\u0646\u062f\u0633\u06d2\u0020\u06a9\u06d2\u0020\u0628\u0639\u062f\u0020\u067e\u062a\u06d2\u0020\u06a9\u0627\u0020\u062d\u0635\u06c1\u0020\u062f\u0631\u062c\u0020\u06a9\u0631\u06cc\u06ba\u06d4\u0020\u067e\u062a\u06c1\u0020\u067e\u0648\u0631\u0627\u0020\u0646\u06c1\u06cc\u06ba\u0020\u06c1\u06d2'
            },
            password: {
                minLength: '\u06a9\u0645\u0020\u0633\u06d2\u0020\u06a9\u0645\u0020\u0686\u06be\u06d2\u0020\u06c1\u0646\u062f\u0633\u0648\u06ba\u0020\u06a9\u06cc\u0020\u062a\u0639\u062f\u0627\u062f\u0020\u0020\u06a9\u06cc\u0020\u0627\u062c\u0627\u0632\u062a\u0020\u06c1\u06d2: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u062f\u0631\u062c\u0020\u06a9\u0626\u06d2\u0020\u06af\u0626\u06d2\u0020\u067e\u0627\u0633\u0648\u0631\u0688\u0632\u0020\u0627\u06cc\u06a9\u0020\u062c\u06cc\u0633\u06d2\u0020\u0646\u06c1\u06cc\u06ba\u0020\u06c1\u06cc\u06ba'
            },
            ofertaAgreement: {
                notChecked: '\u0051\u0061\u0079\u0064\u0061\u006c\u0061\u0072\u0131\u006e\u0131\u007a\u0131\u0020\u0071\u0259\u0062\u0075\u006c\u0020\u0065\u0074\u0064\u0069\u0079\u0069\u006e\u0069\u007a\u0069\u006e\u0069\u007a\u0069\u0020\u0074\u0259\u0073\u0064\u0069\u0071\u006c\u0259\u0079\u0069\u006e'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});